import { makeStyles } from '@material-ui/core';
import cn from 'classnames';
import React from 'react';

import {
  IComparisonTable,
  IComparisonTableHeader,
  IComparisonTableMetadata,
  ImageObjectData,
} from '~/types/comparisonTable';

import TableBody from './Body/TableBody';
import TableFooter from './Footer/TableFooter';
import TableHeader from './Header/TableHeader';

const useStyles = makeStyles({
  root: {
    borderSpacing: 0,
  },
});

interface IProps {
  data: IComparisonTable;
  image?: ImageObjectData;
  className?: string;
}

const Table: React.FunctionComponent<IProps> = ({
  data,
  className,
  image = {} as ImageObjectData,
}) => {
  const classes = useStyles();

  const { metadata = {} as IComparisonTableMetadata, items = [] } = data;

  const headers = items.map((item) => ({
    featured: item.featured,
    ...(item.header || ({} as IComparisonTableHeader)),
  }));

  return (
    <table className={cn(classes.root, className)}>
      <TableHeader header={metadata.header} image={image} items={headers} />
      <TableBody dimensions={metadata.dimensions} items={items} />
      <TableFooter title={metadata.footer.title} items={items} />
    </table>
  );
};

export default Table;
