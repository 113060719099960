import RawHtml from '@finn/auto-ui/components/RawHtml';
import { interactionTrackingEvent, TrackingEventName } from '@finn/ua-tracking';
import { useIsMobile } from '@finn/ui-utils';
import { makeStyles, Theme } from '@material-ui/core';
import cn from 'classnames';
import React, { SyntheticEvent } from 'react';

import { IComparisonTableItem } from '~/types/comparisonTable';

import Action from '../../Action';
import Price from '../../Price';

const useStyles = makeStyles((theme: Theme) => ({
  row: {
    height: '100px',
  },
  summaryRow: {
    height: '36px',
    [theme.breakpoints.up('sm')]: {
      height: '38px',
    },
  },
  empty: {
    height: '0 !important',
  },
  cell: {
    textAlign: 'center',
    padding: theme.spacing(2),
  },
  price: {
    marginBottom: theme.spacing(2),
  },
  summaryRowTitle: {
    paddingTop: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      paddingRight: theme.spacing(1),
    },
  },
  summaryRowText: {
    textAlign: 'center',
  },
}));

interface IProps {
  title: string;
  items: IComparisonTableItem[];
  summaryRow?: string;
}
const TableFooter: React.FunctionComponent<IProps> = ({
  title,
  items,
  summaryRow = '',
}) => {
  const classes = useStyles();
  const isMobile = useIsMobile();

  const handleSourceLinkClicked = (event: SyntheticEvent) => {
    if ((event.target as HTMLAnchorElement).tagName === 'A') {
      interactionTrackingEvent(TrackingEventName.SOURCE_LINK_CLICKED, {
        type: 'leasingmarkt',
      });
    }
  };

  return (
    <tfoot>
      <tr
        className={[
          summaryRow ? classes.summaryRow : classes.row,
          !title && classes.empty,
        ].join(' ')}
      >
        {summaryRow ? (
          <>
            <td className={cn(classes.summaryRowTitle, 'body-12-light')}>
              {!isMobile && (
                <div onClick={(event) => handleSourceLinkClicked(event)}>
                  <RawHtml html={title} />
                </div>
              )}
            </td>
            <td
              className={cn(
                classes.summaryRowText,
                'body-14-semibold sm:body-16-semibold bg-green text-white'
              )}
              colSpan={2}
            >
              {summaryRow}
            </td>
          </>
        ) : (
          <>
            <td>{title}</td>
            {items.map((item, idx) => (
              <td
                key={idx}
                className={cn(classes.cell, {
                  'rounded-bl-sm rounded-br-sm bg-black text-white':
                    item.featured,
                })}
              >
                {item.footer?.price && (
                  <Price price={item.footer.price} className={classes.price} />
                )}
                {item.footer?.action && <Action action={item.footer.action} />}
              </td>
            ))}
          </>
        )}
      </tr>
      {isMobile && summaryRow && (
        <tr>
          <td className={classes.summaryRowTitle} colSpan={3}>
            <RawHtml html={title} />
          </td>
        </tr>
      )}
    </tfoot>
  );
};

export default TableFooter;
