import { cn, useFormattedPrice } from '@finn/ui-utils';
import { makeStyles } from '@material-ui/core';
import React from 'react';

import { IComparisonTableHeaderPrice } from '~/types/comparisonTable';

const useStyles = makeStyles((theme) => ({
  description: {
    marginLeft: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      marginLeft: 0,
    },
  },
}));

interface IProps {
  price: IComparisonTableHeaderPrice;
  className?: string;
}

const Price: React.FunctionComponent<IProps> = ({ price, className }) => {
  const classes = useStyles();
  const priceValue = useFormattedPrice(price.value, 0);

  return (
    <div className={className}>
      <span className="mobile-t4-semibold md:web-t4-semibold">
        {priceValue}
      </span>
      <span className={cn(classes.description, 'body-14-regular text-white')}>
        {price.description}
      </span>
    </div>
  );
};

export default Price;
