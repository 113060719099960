import { cn } from '@finn/ui-utils';
import { makeStyles } from '@material-ui/core';
import React from 'react';

import {
  IComparisonTableBaseAction,
  IComparisonTableFooterLinkAction,
} from '~/types/comparisonTable';

import AddToCart from './AddToCart';
import LinkFooterAction from './LinkFooterAction';

export const useFooterActionStyles = makeStyles((theme) => ({
  button: {
    display: 'flex',
    height: '48px',
    margin: theme.spacing(3, 0),
    padding: theme.spacing(2),
    boxShadow: 'none',
    '&:hover': {
      boxShadow: 'none',
    },
    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(3),
    },
  },
}));

interface IProps {
  action: IComparisonTableBaseAction;
  fullWidth?: boolean;
}
const Action: React.FunctionComponent<IProps> = ({ action, fullWidth }) => {
  const classes = useFooterActionStyles();

  switch (action.type) {
    case 'link':
      return (
        <LinkFooterAction
          action={action as IComparisonTableFooterLinkAction}
          fullWidth={fullWidth}
        />
      );
    case 'add_to_cart':
      return (
        <AddToCart
          title={action.title}
          buttonClassName={cn(classes.button, 'body-16-semibold')}
          isMainButton={false}
        />
      );
    default:
      return null;
  }
};

export default Action;
