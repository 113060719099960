import React from 'react';

interface IProps {
  value: boolean;
}
const CheckboxTableCell: React.FunctionComponent<IProps> = ({ value }) => (
  <span>{value}</span>
);

export default CheckboxTableCell;
