import RawHtml from '@finn/auto-ui/components/RawHtml';
import { interactionTrackingEvent, TrackingEventName } from '@finn/ua-tracking';
import { makeStyles } from '@material-ui/core';
import cn from 'classnames';
import React from 'react';

import { IComparisonTableItemBaseValue } from '~/types/comparisonTable';

import CheckboxTableCell from './CheckboxTableCell';
import TextTableCell from './TextTableCell';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  rootVariant: {
    flexDirection: 'column',
  },
});
interface IProps {
  featured: boolean;
  data: IComparisonTableItemBaseValue;
}
const TableCellData: React.FunctionComponent<IProps> = ({ featured, data }) => {
  const classes = useStyles();

  let Component;
  switch (data.type) {
    case 'checkbox':
      Component = CheckboxTableCell;
      break;
    case 'text':
      Component = TextTableCell;
      break;
  }

  const handleSourceLinkClicked = (sourceType: string) => {
    interactionTrackingEvent(TrackingEventName.SOURCE_LINK_CLICKED, {
      type: sourceType,
    });
  };

  return (
    <div
      className={cn(classes.root, {
        [classes.rootVariant]: data?.additionalText,
      })}
    >
      {Component && <Component featured={featured} value={data.value} />}
      {data.misc}
      {data?.additionalText && (
        <div
          onClick={
            data?.sourceType &&
            (() => handleSourceLinkClicked(data?.sourceType))
          }
        >
          <RawHtml html={data.additionalText} className="body-light-12" />
        </div>
      )}
    </div>
  );
};

export default TableCellData;
